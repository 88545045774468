export default function SubNav() {
	return (
		<div className="relative overflow-hidden">
			<div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
				<div className="relative h-full">
				</div>
			</div>

			<div className="max-w-7xl mt-5 mx-auto px-4 sm:px-6">
				<div className="h-64"></div>
			</div>
		</div>
	)
}
