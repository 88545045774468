import { useReducer, createContext } from 'react'

export const AuthContext = createContext()

const initialState = {
	user: {
		email: '',
		firstname: '',
		lastname: '',
		profile_pic: '',
	},
	step: {
		source_of_funds: false,
		kyb: false,
	},

	authenticated: false,
	online_socket: {},
}

function setAuth(auth) {
	return auth
}


function setStep(step) {
	return step
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_AUTH':
			return setAuth(action.payload)
		case 'SET_STEP':
			return setStep(action.payload)
		default:
			return state
	}
}

export const AuthContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<AuthContext.Provider value={[state, dispatch]}>
			{props.children}
		</AuthContext.Provider>
	)
}
