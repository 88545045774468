import Searchbar from '../ui/SearchBar'
import MiniProfile from '../user/MiniProfile'

export default function Navbar() {
	return (
		<nav className='flex flex-shrink-0'>
			<div className='w-48 mx-auto mt-5 mb-5'>
				<img
					className='rounded-xl'
					src='/img/logo/logo.png'></img>
			</div>
			<div className='max-w-7xl mx-auto py-3'>
				<div className='z-5 ml-2'>
					<MiniProfile></MiniProfile>
				</div>
			</div>
		</nav>
	)
}
