import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import Input from '@/components/ui/Input'
import ListSelector from '@/components/ui/ListSelector'
import Textarea from '@/components/ui/Textarea'
import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import {
    faBuilding,
    faBusinessTime,
    faPassport, faTrash, faUser,
} from '@fortawesome/pro-duotone-svg-icons'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function SourceOfFunds(props) {
    const [loginState, loginDispatch] = useContext(LoginContext)
    const [, authDispatch] = useContext(AuthContext)
    const [ready, setReady] = useState(false)
    const [updateNeeded, setUpdateNeeded] = useState(false)
    const width = Hooks()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)


    const [sourceOfFunds, setSourceOfFunds] = useState([])

    const [nextReady, setNextReady] = useState(false)

    async function setNewOrigine(origine) {
        setUpdateNeeded(true)
        let newSourceOfFunds = sourceOfFunds
        await newSourceOfFunds.push({
            "origine": origine,
            "description": "",
            "date": "",
            "newDate": new Date(),
            "amount": "",
            "invest_amount": ""
        })

        setSourceOfFunds(newSourceOfFunds)
        CheckNextStatus()
        setUpdateNeeded(false)
    }

    async function removeOrigine(index) {
        setUpdateNeeded(true)
        let newSourceOfFunds = sourceOfFunds
        await newSourceOfFunds.splice(index, 1);
        setSourceOfFunds(newSourceOfFunds)
        CheckNextStatus()
        setUpdateNeeded(false)
    }

    async function setDescription(description, index) {
        setUpdateNeeded(true)
        let newSourceOfFunds = sourceOfFunds
        newSourceOfFunds[index].description = description
        await setSourceOfFunds(newSourceOfFunds)
        CheckNextStatus()
        setUpdateNeeded(false)
    }

    async function setAmount(amount, index) {
        setUpdateNeeded(true)
        let newSourceOfFunds = sourceOfFunds
        newSourceOfFunds[index].amount = amount
        await setSourceOfFunds(newSourceOfFunds)
        CheckNextStatus()
        setUpdateNeeded(false)
    }

    async function setDate(date, index) {
        setUpdateNeeded(true)
        let newSourceOfFunds = sourceOfFunds
        newSourceOfFunds[index].date = date
        await setSourceOfFunds(newSourceOfFunds)
        CheckNextStatus()
        setUpdateNeeded(false)
    }

    async function setNewDate(newDate, index) {
        setUpdateNeeded(true)
        let newSourceOfFunds = sourceOfFunds
        newSourceOfFunds[index].newDate = newDate
        await setSourceOfFunds(newSourceOfFunds)
        CheckNextStatus()
        setUpdateNeeded(false)
    }

    async function setInvestAmount(invest_amount, index) {
        setUpdateNeeded(true)
        let newSourceOfFunds = sourceOfFunds
        newSourceOfFunds[index].invest_amount = invest_amount
        await setSourceOfFunds(newSourceOfFunds)
        CheckNextStatus()
        setUpdateNeeded(false)
    }

    const sources = {
        "Sale of real estate": {
            "description": "Description",
            "date": "Date of the sale",
            "amount": "Amount of the sale",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Sale of movable property": {
            "description": "Description",
            "date": "Date of the sale",
            "amount": "Amount of the sale",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Sale or transfer of title": {
            "description": "Description",
            "date": "Date of the sale or transfer",
            "amount": "Amount of the sale or transfer",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Personal savings / professional income": {
            "description": "Description",
            "date": "Date of the constitution of the savings",
            "amount": "Amount of the savings",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Legacy": {
            "description": "Description",
            "date": "Date of the legacy",
            "amount": "Amount of the legacy",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Donation": {
            "description": "Description",
            "date": "Date of the donation",
            "amount": "Amount of the donation",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Replacement of a matured investment": {
            "description": "Description",
            "date": "Maturity date of this investment",
            "amount": "Amount of the investment",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Replacement of an indemnity (accident or other)": {
            "description": "Description",
            "date": "Date of the event giving rise to the indemnity",
            "amount": "Amount of the indemnity",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Loan": {
            "description": "Description",
            "date": "Date of the loan",
            "amount": "Amount of the loan",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Gain (games)": {
            "description": "Description",
            "date": "Date of the gain",
            "amount": "Amount of the gain",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
        "Other": {
            "description": "Description",
            "date": "Date",
            "amount": "Amount",
            "invest_amount": "Amount in €/$ invested in DCY srl"
        },
    }

    const choices = [
        {
            "value": "Sale of real estate",
            "label": "Sale of real estate",
        },
        {
            "value": "Sale of movable property",
            "label": "Sale of movable property",
        },
        {
            "value": "Sale or transfer of title",
            "label": "Sale or transfer of title",
        },
        {
            "value": "Personal savings / professional income",
            "label": "Personal savings / professional income",
        },
        {
            "value": "Legacy",
            "label": "Legacy",
        },
        {
            "value": "Donation",
            "label": "Donation",
        },
        {
            "value": "Replacement of a matured investment",
            "label": "Replacement of a matured investment",
        },
        {
            "value": "Replacement of an indemnity (accident or other)",
            "label": "Replacement of an indemnity (accident or other)",
        },
        {
            "value": "Loan",
            "label": "Loan",
        },
        {
            "value": "Gain (games)",
            "label": "Gain (games)",
        },
        {
            "value": "Other",
            "label": "Other",
        }
    ]

    async function CheckNextStatus() {
        setUpdateNeeded(true)
        if (sourceOfFunds.length === 0) {
            setNextReady(false)
            setUpdateNeeded(false)
            return
        }

        let ready = true
        await sourceOfFunds.forEach(source => {
            if (source.origine === "" || source.amount === "" || source.date === "" || source.invest_amount === "" || source.description === "") {
                ready = false
            }
        });

        if (ready) {
            setNextReady(true)
        } else {
            setNextReady(false)
        }
        setUpdateNeeded(false)
    }


    useEffect(async () => {
        AuthService.Me()
            .then(async (data) => {

                let sources = []
                await data.source_of_funds.forEach(source => {
                    sources.push({
                        "origine": source.origine,
                        "description": source.description,
                        "date": source.date,
                        "newDate": new Date(),
                        "amount": source.amount,
                        "invest_amount": source.invest_amount
                    })
                })

                setSourceOfFunds(sources)

                console.log("data")
                console.log(data)
                // setOrigine(data.source_of_funds.origine)
                // setDescription(data.source_of_funds.description)
                // setDate(data.source_of_funds.date)
                // setAmount(data.source_of_funds.amount)
                // setInvestAmount(data.source_of_funds.invest_amount)

                authDispatch({
                    type: 'SET_AUTH',
                    payload: {
                        user: {
                            email: data.email,
                            firstname: data.firstname,
                            lastname: data.lastname,
                            profile_pic: data.profile_pic,
                        },
                        iam: data.iam,
                        authenticated: true,
                    },
                })

                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: data.session,
                            account_type: data.session_type,
                            source_of_funds: data.steps.source_of_funds,
                            kyb: data.steps.kyb,
                        },
                    },
                })

                setReady(true)
            })
            .catch((err) => {
                console.log(err)
                authDispatch({
                    type: 'SET_AUTH',
                    payload: {
                        user: {
                            email: '',
                            firstname: '',
                            lastname: '',
                            profile_pic: '',
                        },
                        iam: [],
                        authenticated: false,
                    },
                })
                setReady(true)
            })
        return () => { }
    }, [])

    async function goNext() {
        setLoading(true)
        setError("")

        let body = {
            "source_of_funds": sourceOfFunds,
        }

        await AuthService.Next(body)
            .then((data) => {
                setLoading(false)
                loginDispatch({
                    type: 'SET_LOGIN',
                    payload: {
                        auth: {
                            session_id: loginState.auth.session_id,
                            account_type: loginState.auth.account_type,
                            source_of_funds: true,
                            kyb: loginState.auth.kyb,
                        },
                    },
                })
                props.onNext()
            })
            .catch((err) => {
                setLoading(false)
                setError(err.message)
            })
        return () => { }
    }

    return (
        <div>
            <div>
                {ready ?
                    <>
                        {!updateNeeded ?
                            <SubNav></SubNav> : null}
                        <div className='max-w-7xl mx-auto relative'>
                            <div className='dashboard-container'>
                                <div className='mx-auto md:justify-between mt-3'>
                                    <div className='bg-white rounded-xl p-6 shadow'>
                                        <h3 className="text-2xl text-primary-800 mb-1 font-bold">Source of funds</h3>
                                        <span>What are the major source of funding of your business ?</span>
                                        <div className='w-full mt-5 pr-3'>
                                            <div className="w-1/2">
                                                <ListSelector className="checkbox mr-3 ml-2"
                                                    choices={choices}
                                                    onSelect={(e) => setNewOrigine(e)} />
                                            </div>
                                        </div>
                                        {sourceOfFunds.map((source, index) => {
                                            return (
                                                <div key={index} className='bg-white rounded-xl p-6 mt-5 shadow'>
                                                    <div className='flex justify-between'>
                                                        <p className="block text-xl font-medium text-primary-700">{source.origine}</p>
                                                        <div onClick={() => removeOrigine(index)} className='cursor-pointer hover:bg-red-500 transition-25 transition duration-200'>
                                                            <FontAwesomeIcon
                                                                className={'text-gray-500 mx-auto mt-3 text-xl mt-0.5'}
                                                                icon={faTrash}></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                    <div className='w-full mt-5 pr-3'>
                                                        <p className="block text-sm font-medium text-primary-700 mb-2">{sources[source.origine]["description"]}</p>
                                                        <Textarea
                                                            setValue={(e) => setDescription(e, index)}
                                                            placeholder=''
                                                            name='email'
                                                            value={source.description}
                                                        />
                                                    </div>
                                                    <div className='w-full mt-5 pr-3'>
                                                        <div className='flex'>
                                                            <div className='pr-3'>
                                                                <p className="block text-sm font-medium text-primary-700 mb-2">{sources[source.origine]["date"]}</p>
                                                                <ReactDatePicker
                                                                    className="border rounded-lg p-3 font-bold"
                                                                    selected={source.newDate}
                                                                    onChange={(date) => setDate(moment(date).format('YYYY-MM-DD'), index) & setNewDate(date, index)}
                                                                    maxDate={new Date()}
                                                                    dateFormat="yyyy-MM-dd"
                                                                    placeholderText="Select date"
                                                                />
                                                            </div>
                                                            <div className='w-full pl-3 pr-3'>
                                                                <p className="block text-sm font-medium text-primary-700 mb-2">{sources[source.origine]["amount"]}</p>
                                                                <Input
                                                                    setValue={(e) => setAmount(e, index)}
                                                                    placeholder=''
                                                                    name='email'
                                                                    value={source.amount}
                                                                />
                                                            </div>
                                                            <div className='w-full pl-3'>
                                                                <p className="block text-sm font-medium text-primary-700 mb-2">{sources[source.origine]["invest_amount"]}</p>
                                                                <Input
                                                                    setValue={(e) => setInvestAmount(e, index)}
                                                                    placeholder=''
                                                                    name='email'
                                                                    value={source.invest_amount}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className='bg-white rounded-xl p-6 mt-5 shadow'>
                                            <div className='flex justify-between'>
                                                <div>
                                                </div>
                                                {error !== "" ? <>
                                                    <p className='text-red-500'>{error}</p>
                                                </> : null}
                                                <div>
                                                    <Button
                                                        type='submit'
                                                        full
                                                        loading={loading}
                                                        label='Next'
                                                        disabled={!nextReady}
                                                        onClick={() => goNext()}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : null}
            </div>
        </div >
    )
}
