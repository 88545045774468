import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import SynapsClient from '@synaps-io/verify.js'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import WorkflowSteps from "./WorkflowSteps";
import Verification from "./Verification";
import SourceOfFunds from "./SourceOfFunds";

export default function DashboardPage() {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const [stepID, setStepID] = useState(0)
	const width = Hooks()

	const CORPORATE_STEPS_LIST = {
		0: "SOURCE_OF_FUNDS",
		1: "KYB",
	}

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					if (data.steps.source_of_funds === false) {
						setStepID(0)
					} else {
						setStepID(1)
					}

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
								intro: data.steps.intro,
								personal: data.steps.personal,
								company: data.steps.company,
								source_of_funds: data.steps.source_of_funds,
								regulated_entities: data.steps.regulated_entities,
								declaration: data.steps.declaration,
								data_protection: data.steps.data_protection,
								kyb: data.steps.kyb,
							},
						},
					})

					setReady(true)
				})
				.catch((err) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			if (loginState.auth.source_of_funds === false) {
				setStepID(0)
			} else {
				setStepID(1)
			}
			setReady(true)
		}
		return () => { }
	}, [])

	return (
		<div>
			{ready ?
				<div>
					<WorkflowSteps current={CORPORATE_STEPS_LIST[stepID]} data_protection={loginState.auth.data_protection} declaration={loginState.auth.declaration} regulated_entities={loginState.auth.regulated_entities} source_of_funds={loginState.auth.source_of_funds} intro={loginState.auth.intro} company={loginState.auth.company} personal={loginState.auth.personal} kyb={loginState.auth.kyb}></WorkflowSteps>
					<div>

						{CORPORATE_STEPS_LIST[stepID] === "SOURCE_OF_FUNDS" ?
							<SourceOfFunds onNext={() => setStepID(stepID + 1)} onBack={() => setStepID(stepID - 1)}></SourceOfFunds>
							: null}

						{CORPORATE_STEPS_LIST[stepID] === "KYB" ?
							<Verification onBack={() => setStepID(stepID - 1)} ></Verification>
							: null}

					</div>
					<div className='max-w-7xl mx-auto relative'>
						<Footer></Footer>
					</div>
				</div> : null}
		</div>
	)
}
