import InitSignup from '@/components/auth/InitSignup'
import { useReducer, createContext } from 'react'

export const LoginContext = createContext()

const initialState = {
	auth: {
		email: '',
		firstname: '',
		lastname: '',
		profile_pic: '',
		iam: [],
		code: '',
		account_type: '',
		session_id: '',
		source_of_funds: false,
		kyb: false,
	},
	currentComponent: <InitSignup></InitSignup>,
}

function setLogin(login) {
	return login
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_LOGIN':
			return setLogin(action.payload)
		default:
			return state
	}
}

export const LoginContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<LoginContext.Provider value={[state, dispatch]}>
			{props.children}
		</LoginContext.Provider>
	)
}
