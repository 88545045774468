import { useState } from 'react'

export default function ListSelector(props) {
	const [focus, setFocus] = useState(false)
	return (
		<div>
			<div className="relative">
				<button
					disabled={props.disabled}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					type="button"
					className="bg-white relative w-full border border-gray-300 rounded-xl shadow-sm pl-3 pr-10 py-2.5 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
					aria-haspopup="listbox"
					aria-expanded="true"
					aria-labelledby="listbox-label">
					<span className="block truncate">
						{props.selected ? props.selected : 'Select'}
					</span>
					<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
						<svg
							className="h-5 w-5 text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true">
							<path
								fillRule="evenodd"
								d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
					</span>
				</button>

				{focus ? (
					<ul
						className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
						tabIndex="-1"
						role="listbox"
						aria-labelledby="listbox-label"
						aria-activedescendant="listbox-option-3">
						{props.choices.map((suggestion, i) => {
							return (
								<li
									key={i}
									onMouseDown={() => props.onSelect(suggestion.value)}
									className="text-gray-900 cursor-pointer hover:bg-bluegray-200 transition ease-in duration-100 select-none relative py-2 pl-3 pr-9"
									id="listbox-option-0"
									role="option">
									<div className="flex items-center">
										<span className="font-normal ml-3 block truncate">
											{suggestion.label}
										</span>
									</div>
								</li>
							)
						})}
					</ul>
				) : null}
			</div>
		</div>
	)
}
